import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const KostenWohnung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Was kostet eine Wohnung wirklich?" showCalc={false} />
            <Article>
                <p>
                    Eine eigene Wohnung bedeutet Sicherheit und Freiheit. Doch der Weg dorthin ist auch eine
                    Herausforderung. Die Kosten und die eigene finanzielle Lage werden beim Wohnungskauf oft
                    unterschätzt. Den Überblick über Maklerprovisionen, Kreditangebote und alle Nebenkosten zu bewahren,
                    ist ganz schön schwierig. Es gibt viele gute Gründe, in eine eigene Wohnung zu investieren. Trotzdem
                    entscheiden sich in Österreich die große Mehrheit für eine Miete, weil es beim Kauf von Immobilien
                    Hürden und viel zu beachten gibt. In diesem kurzen Artikel soll es deshalb darum gehen, was es
                    wirklich kostet, sich den Traum von einer eigenen Wohnung zu erfüllen und welche laufenden Kosten
                    dich als zukünftige*r Wohnungseigentümer*in erwarten.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Kannst du dir eine Wohnung leisten?</h2>
                <p>
                    Vor dem Wohnungskauf solltet du sicher sein, dass deine finanzielle Lage dir überhaupt ermöglicht,
                    eine Wohnung zu kaufen. Gegebenenfalls solltest du dir rechtzeitig Unterstützung von
                    Familienmitgliedern oder Freund*innen suchen. Ob du dir eine Wohnung leisten kannst, hängt vor allem
                    von der Höhe deiner Einkünfte und den Kosten der Wohnung ab. Bevor du eine Wohnung kaufst, solltest
                    du jedoch am besten bereits ein gewisses Startkapital angespart haben. Denn je höher dieses ist,
                    desto günstiger ist dein{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>
                    . Erst danach beginnt die Suche nach dem passenden Kredit.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Beratungstermin vereinbaren
                    </a>
                </p>
                <hr />

                <h2>Welche Nebenkosten beim Kauf einer Eigentumswohnung nicht vermeidbar sind</h2>
                <p>
                    Neben dem Kaufpreis der Wohnung ist auch mit weiteren fixen Kosten beim Wohnungskauf zu rechnen. Im
                    Artikel Kaufnebenkosten haben wir uns diese schon einmal genauer angesehen, klick dort rein, wenn du
                    dich genauer informieren möchtest. Hier fassen wir nur die wichtigsten Aspekte zusammen.
                </p>
                <p>
                    Eine Ausgabe, die man schnell vergisst, ist die für alle Liegenschaften anfallende
                    Grunderwerbssteuer: diese beträgt 3,5% des Kaufpreises. Außerdem musst du für die Eintragung ins
                    Grundbuch eine Gebühr von 1,1% des Kaufpreises zu zahlen. Die Maklerprovision kannst du dir sparen,
                    wenn du nach provisionsfreien Immobilien suchst. Allerdings verfügen Makler*innen über das bessere
                    Sortiment, wofür sich die 3,6% durchaus lohnen können. Die Kosten für Rechtsanwälte und Notare bei
                    der Vertragserrichtung und Kaufabwicklung liegen bei 1% bis zu 3% des Kaufpreises. Hierbei kannst du
                    aber auch eine Pauschale vereinbaren. Insgesamt musst du also um die 7 % des Kaufpreises für
                    Grunderwerbsteuer, Grundbucheintragung und Kosten für Rechtsanwälte und Notare zum Wohnungspreis
                    hinzurechnen, einschließlich Maklerprovision sind es sogar bis zu 11,2%.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Jetzt Finanzierungsgespräch vereinbaren
                    </a>
                </p>
                <hr />

                <h2>Maklerprovision</h2>
                <p>
                    Mit 3,6% des Kaufpreises fällt die Maklerprovision ganz schön hoch an. Wenn du dir diese sparen
                    möchtest, kannst du selbst nach provisionsfreien Wohnungen suchen. Diese sind aber oft weniger
                    hochwertig, da Makler*innen das bessere Sortiment haben. Außerdem bieten seriöse Makler*innen dir
                    eine gute Beratung und helfen dir auf dem Weg der Abwicklung, da sie mit Notaren, Anwälten und
                    häufig auch mit Finanzexperten vernetzt sind.
                </p>
                <p>
                    <strong>Tipp:</strong> Vielleicht verkauft auch jemand in deinem Familien- oder Bekanntenkreis eine
                    tolle Wohnung. Frühzeitiges Nachfragen und Erkundigen schadet jedenfalls nicht, denn vielleicht
                    kannst du dir auch auf diesem Weg die Maklerprovision sparen.
                </p>
                <hr />

                <h2>Fixkosten beim Wohnungskauf</h2>
                <p>
                    Die Grunderwerbssteuer ist beim Immobilienkauf nicht zu umgehen. Diese beträgt in Österreich ca.
                    3,5% des Kaufpreises. Es gibt Sonderfälle für Unternehmen, in denen es lediglich 0,5% sind.
                </p>
                <p>
                    Wenn du dein Wohneigentumsrecht in das Grundbuch eintragen lässt, musst du ca. 1,1% des Kaufpreises
                    dafür bezahlen. Falls du einen Hypothekarkredit aufgenommen hast, trägt die Bank außerdem dein
                    Pfandrecht auch im Grundbuch ein, wofür du noch zusätzliche 1,2% zahlen musst.
                </p>
                <p>
                    Bei der Feststellung des Werts einer Liegenschaft ist es gut, auf zuverlässige beeidete
                    Sachverständige zurückzugreifen. Diese verlangen jedoch etwa 500 bis 1000 Euro pro Schätzung. Bei
                    teureren Immobilien oder umfassenden Beschreibungen verlangen sie oft noch mehr. Mit diesen Kosten
                    musst du gegebenenfalls also auch noch rechnen.
                </p>
                <p>
                    Das Erstgespräch bei Notaren oder Anwählten kostet erstmal nichts. Allerdings zahlst du die Verträge
                    und die Begleitung der Abwicklung beim Kauf einer Wohnung. Diese Kosten orientieren sich am Wert der
                    Immobilie. Der Kaufvertrag wird von Notaren oder Rechtsanwälten errichtet. Wichtige Faktoren wie
                    beispielsweise die Wohnungsausstattung, bekannte Mängel der Wohnung, Übergabetermin und
                    Zahlungsfrist sollten dabei geklärt werden.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kostenlose Beratung holen
                    </a>
                </p>
                <hr />

                <h2>Quadratmeterpreise und Zustand der Wohnung beeinflussen den Preis</h2>
                <p>
                    Die Quadratmeterpreise variieren abhängig von der Lage der Wohnung. Doch nicht nur deshalb ist die
                    Lage ein wichtiges Kriterium bei der Wohnungswahl, sondern vor allem auch, wenn diese als Investment
                    mit Wertsteigerung betrachtet wird. In fünf bis zehn Jahren, kann eine Wohnung durch ihre Lage
                    durchaus an Wert gewinnen. Deshalb sollte man sich auch über die zukünftige Stadtplanung in dem
                    Bereich informieren (ist z.B. der Bau neuer U-Bahn-Stationen geplant?).
                </p>
                <p>
                    In Wien zahlt man aktuell je nach Größe in der Inneren Stadt zwischen 7.000 und 10.000 € pro
                    Quadratmeter. In den restlichen inneren und äußeren Stadtbezirken Wiens liegt der Preis pro
                    Quadratmeter bei ca. 2.700 bis zu 6.500 €.
                </p>
                <p>
                    Der Zustand der Wohnung beeinflusst ihren Preis natürlich auch. Renovierungsbedürftige Wohnungen
                    bekommt man für einen günstigeren Preis, doch muss man hier gut überlegen, ob sich der günstigere
                    Preis mit den Renovierungskosten und dem Aufwand insgesamt wirklich lohnt.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Beratungstermin vereinbaren
                    </a>
                </p>
                <hr />

                <h2>Laufende Kosten als Wohnungseigentümer*in</h2>
                <p>
                    Als Wohnungseigentümer*in erwarten dich selbstverständlich auch nach dem Kauf Betriebskosten
                    laufende Instandhaltungs- und Verwaltungskosten.
                </p>
                <p>Dazu gehören generell:</p>
                <ul>
                    <li>Verwaltungskosten</li>
                    <li>Hausbetreuungskosten (entweder durch externe Hausbetreuung oder Hausbesorger*innen)</li>
                    <li>Wasser- und Abwassergebühren (inkl. Wartungsgebühren)</li>
                    <li>
                        Kosten für die Eichung, Wartung und das Ablesen von Messvorrichtungen zur Verbrauchsermittlung
                    </li>
                    <li>Müllabfuhr</li>
                    <li>Kanalräumungskosten</li>
                    <li>Kosten für die Schädlingsbekämpfung</li>
                    <li>Beleuchtungskosten des allgemeinen Teils des Gebäudes</li>
                    <li>Rauchfangkehrerkosten</li>
                    <li>
                        Versicherungen wie z.B. Feuerversicherung, Haftpflichtversicherung,
                        Leitungswasserschädenversicherung und andere Versicherungen gegen Glasbruch und Sturmschäden
                        (Wenn die Mehrheit der Mieter zustimmt)
                    </li>
                </ul>
                <p>
                    Als Eigentümer*in kommen auch noch weitere Kosten auf dich zu, wie z.B. Ausgaben für
                    Gemeinschaftsanlagen, Rücklagen, Annuitätenzahlungen, also die jährlichen{" "}
                    <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                        Zinsen
                    </Link>{" "}
                    - und Tilgungsraten für Investitionen, etc. Darauf solltest du vorbereitet sein. Unterschätze diese
                    Kosten nicht, wenn du die Höhe deiner Kreditrate bestimmst.
                </p>
                <hr />

                <h2>Fazit: Lass dich nicht abschrecken, aber informiere dich gut!</h2>
                <p>
                    Beim Wohnungskauf fallen viele Kosten an, die anfangs einschüchternd wirken können. Doch wenn du gut
                    im voraus planst, dich professionell beraten lässt und nicht voreilig handelst, kann sich der Kauf
                    einer Wohnung durchaus lohnen. Eine eigene Wohnung bietet dir als Eigentümer*in nicht nur absolute
                    Sicherheit und Freiheit im Vergleich zu Mietwohnungen, sondern ist auch ein hervorragendes
                    Investment. Dir gleich zu Beginn professionelle Unterstützung für den gesamten Prozess zu suchen,
                    spart dir später viel Zeit, Geld und Nerven.
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"kostenWohnung"}></BreadcrumbList>
            <ArticleStructuredData page={"kostenWohnung"} heading={"Was kostet eine Wohnung wirklich?"} />
        </Layout>
    );
};

export default KostenWohnung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.kosten-wohnung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
